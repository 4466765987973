import config from '@/lib/config';
import alsContext from './als';

import Pino, { LoggerOptions, DestinationStream } from 'pino';

const loggerOptions: LoggerOptions = {
    level: 'info',
    formatters: {
        level(label) {
            return { level: label };
        }
    }
};

if (config.isDevelopmentEnv) {
    loggerOptions.transport = {
        target: 'pino-pretty',
        options: {
            translateTime: 'SYS:dd-mm-yyyy HH:MM:ss',
            colorize: true
        }
    };
}

export const testLogger: DestinationStream = {
    write: msg => {
        const json = JSON.parse(msg);
        console.log(json); // eslint-disable-line no-console
        return json;
    }
};

let aTransport: DestinationStream = process.stdout;

if (config.isProductionEnv || config.isStagingEnv) {
    loggerOptions.transport = {
        target: 'pino-datadog-transport',
        options: {
            service: 'hazmanina-server',
            ddtags: `env:${config.env}`,
            ddClientConf: {
                authMethods: {
                    apiKeyAuth: config.datadog.apiKey
                }
            },
            ddServerConf: {
                site: config.datadog.site
            }
        }
    };
} else if (config.isTestEnv) {
    aTransport = testLogger;
}

export const rawLogger = Pino(loggerOptions, aTransport);

export const logger = new Proxy(rawLogger, {
    get(target, property, receiver) {
        const contextualLogger = alsContext?.getStore()?.get('logger');
        target = contextualLogger || target;
        return Reflect.get(target, property, receiver);
    }
});

export default logger;
