import React, { createContext, useContext, useState, ReactNode } from 'react';
import { createGlobalStyle } from 'styled-components';

const DisableDivScrollStyle = createGlobalStyle`
  html, body {
    overflow: hidden;
    touch-action: none;
  }
`;

interface ScrollContextType {
    disableScroll: () => void;
    enableScroll: () => void;
    isScrollDisabled: boolean;
}

const ScrollContext = createContext<ScrollContextType | undefined>(undefined);

export const ScrollProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isScrollDisabled, setIsScrollDisabled] = useState(false);

    const disableScroll = () => setIsScrollDisabled(true);
    const enableScroll = () => setIsScrollDisabled(false);

    return (
        <ScrollContext.Provider value={{ disableScroll, enableScroll, isScrollDisabled }}>
            {isScrollDisabled && <DisableDivScrollStyle />}
            {children}
        </ScrollContext.Provider>
    );
};

export const useScroll = (): ScrollContextType => {
    const context = useContext(ScrollContext);
    if (!context) {
        throw new Error('useScroll must be used within a ScrollProvider');
    }
    return context;
};
