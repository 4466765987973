type TEnv = 'development' | 'test' | 'staging' | 'production';

const canvasWidth = 500;
const canvasHeight = (canvasWidth * 7) / 5;
const previewWidth = 300;
const previewHeight = (previewWidth * 7) / 5;
const printWidth = 1500;
const printwHeight = (printWidth * 7) / 5;
const env = (process.env.HAZMANINA_ENV as TEnv) || 'development';
const isDevelopmentEnv = env === 'development' || env === 'staging';
const isStagingEnv = env === 'staging';
const isProductionEnv = env === 'production';
const isTestEnv = env === 'test';
const isApolloStudioEnabled = isDevelopmentEnv;
const gqlRateLimitPeriodSeconds = Number(process.env.GQL_RATE_LIMIT_PERIOD_SECONDS) || 60;
const gqlRateLimitMaxRequests = Number(process.env.GQL_RATE_LIMIT_MAX_REQUESTS) || 100;
const isAuthDisabled = !isProductionEnv;
const googleClientId = process.env.GOOGLE_CLIENT_ID || '';
const googleClientSecret = process.env.GOOGLE_CLIENT_SECRET || '';
const facebookClientId = process.env.FACEBOOK_CLIENT_ID || '';
const facebookClientSecret = process.env.FACEBOOK_CLIENT_SECRET || '';
const mongoDbHashKey = process.env.MONGODB_HASH_KEY || 'key';
const googleTemplatesStorageUrl = process.env.GOOGLE_TEMPLATES_CDN || 'https://templates.hazmanina.co.il';
const fontsBaseUrl = `${googleTemplatesStorageUrl}/fonts`;
const backgroundBaseUrl = `${googleTemplatesStorageUrl}/backgrounds`;
const paperBaseUrl = `${googleTemplatesStorageUrl}/papers`;
const paperAssetUrl = `${paperBaseUrl}/assets`;
const paperThubmanilUrl = `${paperBaseUrl}/thumbnails`;
const templatePreviewBaseUrl = `${googleTemplatesStorageUrl}/preview`;
const templatesBaseUrl = `${googleTemplatesStorageUrl}/model/templates`;
const projectPreviewsBucketName = isProductionEnv ? 'hazmanina-projects' : 'hazmanina-projects-staging';
const templateBucketName = 'hazmanina-templates';
const googleProjectsPreviewStorageUrl = `https://storage.googleapis.com/${projectPreviewsBucketName}`;
const projectPreviewBaseUrl = `${googleProjectsPreviewStorageUrl}/previews`;
const mailerAddress = process.env.MAILER_ADDRESS;
const mailerPassword = process.env.MAILER_PASSWORD;
const navigationBarHeight = Number(process.env.NAVIGATION_BAR_HEIGHT_PX) || 60;
const bottomBarHeight = Number(process.env.BOTTOM_BAR_HEIGHT_PX) || 200;
const templatesCacheTTL = Number(process.env.TEMPLATES_CATALOG_CACHE_TTL) || isProductionEnv ? 5 * 60 : 1;
const yaadpayMasof = process.env.YAAD_MASOF;
const yaadpayApiKey = process.env.YAAD_API_KEY;
const yaadpayPassP = process.env.YAAD_PASSP;
const yaadpayUrl = 'https://icom.yaad.net/p/';
const datadogApiKey = process.env.DATADOG_API_KEY || '';
const datadogSite = process.env.DATADOG_SITE || 'datadoghq.eu';
const datadogAgentHost = process.env.DD_AGENT_HOST || '';
const datadogAgentPort = process.env.DD_AGENT_SYSLOG_PORT || '';
const datadogAppKey = process.env.NEXT_PUBLIC_DD_RUN_APP_ID || '';
const allowedIps = process.env.ALLOWED_IPS ? process.env.ALLOWED_IPS?.split(',').map(ip => ip.trim()) : [];
const redisNamespace = process.env.REDIS_NAMESPACE || 'hazmanina';
const googlePayApiKey = process.env.GOOGLE_PAY_API_KEY || '';
const googlePayMerchantId = process.env.GOOGLE_PAY_MERCHANT_ID || '';
const googlePayApiUrl = process.env.GOOGLE_PAY_API_URL || 'https://api.googlepay.com';
const googlePayEnvironment = isProductionEnv ? 'PRODUCTION' : 'TEST';

const config = {
    ui: {
        navbarHeight: navigationBarHeight,
        bottomBarHeight
    },
    canvas: {
        width: canvasWidth,
        height: canvasHeight,
        snappingThreshold: 0.06
    },
    print: {
        width: printWidth,
        height: printwHeight
    },
    preview: {
        width: previewWidth,
        height: previewHeight
    },
    watermark: {
        color: 'red',
        width: canvasWidth / 500
    },
    text: {
        color: 'black',
        hoverColor: 'gray'
    },
    mailer: {
        address: mailerAddress,
        password: mailerPassword
    },
    env,
    isProductionEnv,
    isDevelopmentEnv,
    isStagingEnv,
    isTestEnv,
    isApolloStudioEnabled,
    database: {
        dbName: 'hazmanina'
    },
    gqlRateLimitPeriodSeconds,
    gqlRateLimitMaxRequests,
    isAuthDisabled,
    allowedIps,
    googleLogin: {
        clientId: googleClientId,
        secret: googleClientSecret
    },
    facebookLogin: {
        clientId: facebookClientId,
        secret: facebookClientSecret
    },
    mongoDbHashKey,
    templates: {
        bucketName: templateBucketName,
        fontsBaseUrl,
        backgroundBaseUrl,
        paperBaseUrl,
        paper: {
            assetUrl: paperAssetUrl,
            thumbnailUrl: paperThubmanilUrl
        },
        templatesBaseUrl,
        templatePreviewBaseUrl,
        storageUrl: googleTemplatesStorageUrl,
        cacheTTL: templatesCacheTTL
    },
    projectsPreviewsBucketName: projectPreviewsBucketName,
    projectPreviewBaseUrl,
    yaad: {
        masof: yaadpayMasof,
        apiKey: yaadpayApiKey,
        PassP: yaadpayPassP,
        url: yaadpayUrl
    },
    datadog: {
        apiKey: datadogApiKey,
        site: datadogSite,
        agentHost: datadogAgentHost,
        agentPort: datadogAgentPort,
        appKey: datadogAppKey
    },
    redis: {
        namespace: redisNamespace
    },
    googlePay: {
        apiKey: googlePayApiKey,
        merchantId: googlePayMerchantId,
        apiUrl: googlePayApiUrl,
        environment: googlePayEnvironment
    }
};

export default config;
