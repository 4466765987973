import 'reflect-metadata';
import React, { useEffect } from 'react';
import { AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import { ApolloProvider } from '@apollo/client';
import useApollo from '@/apollo-client';
import { GlobalStyles } from '@/styles/globals';
import { styled } from 'styled-components';
import { NextUIProvider } from '@nextui-org/system';
import '@/styles/tailwind.css';
// import { useRouter } from 'next/router';
// import { AuthPages } from '@/lib/types/types';
import { LoaderProvider } from '@/components/LoaderProvider';
import { ScrollProvider } from '@/components/providers/ScrollProvider';
import SEOHead from '@/components/SEOHead';
import DatadogRumInit from '@/components/DatadogRumInit';
import { Toaster } from 'react-hot-toast';
import GoogleAnalyticsScript from '@/components/GoogleAnalyticsScript';
import { isProduction } from '@/utils/client-utils';
import '@/styles/apple-pay.css';

const MyApp: React.FC<AppProps> = ({ Component, pageProps: { session, ...pageProps } }: AppProps) => {
    const apolloClient = useApollo();
    const isProductionEnv = isProduction();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <SEOHead />
            {isProductionEnv && <GoogleAnalyticsScript />}
            <DatadogRumInit />
            <GlobalStyles />
            <SessionProvider session={session} refetchInterval={30 * 60} refetchOnWindowFocus={true}>
                <ApolloProvider client={apolloClient}>
                    <NextUIProvider>
                        <ScrollProvider>
                            <LoaderProvider>
                                <Container className="dark">
                                    <InnerContainer>
                                        <Component {...pageProps} />
                                    </InnerContainer>
                                    <Toaster />
                                </Container>
                            </LoaderProvider>
                        </ScrollProvider>
                    </NextUIProvider>
                </ApolloProvider>
            </SessionProvider>
        </>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 872px) {
        width: 100%;
    }
`;

const InnerContainer = styled.div`
    width: min(100%, 2000px);
    min-height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 872px) {
        width: 100%;
    }
`;

export default MyApp;
