import Script from 'next/script';

const GoogleAnalyticsScript = () => (
    <>
        <Script strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=G-XM6S9EDFTQ`} />
        <Script
            id="google-analytics"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
                __html: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-XM6S9EDFTQ', {
        page_path: window.location.pathname,
      });
      gtag('config', 'AW-16861542770');
    `
            }}
        />
    </>
);

export default GoogleAnalyticsScript;
