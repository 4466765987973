import Head from 'next/head';
import { useRouter } from 'next/router';

const STATIC_URL = 'https://static.hazmanina.co.il';
const LOGO_URL = `${STATIC_URL}/landing/smiling-letter.webp`;

const SEOHead = () => {
    const router = useRouter();
    const canonicalUrl = `https://www.hazmanina.co.il${router.asPath.split('?')[0]}`;

    return (
        <Head>
            <title>הזמנינא - הזמנות לאירועים</title>
            <meta
                name="description"
                content="עיצוב הזמנות דיגיטליות לכל אירוע עם מעל 100 עיצובים ייחודיים! בחרו הזמנה לחתונה, בר/בת מצווה, חינה, ימי הולדת, בריתות ועוד – והתאימו אותה אישית בקלות."
            />
            <meta property="og:title" content="הזמנינא - הזמנות לאירועים" />
            <meta
                property="og:description"
                content="עיצוב הזמנות דיגיטליות לכל אירוע עם מעל 100 עיצובים ייחודיים! בחרו הזמנה לחתונה, בר/בת מצווה, חינה, ימי הולדת, בריתות ועוד – והתאימו אותה אישית בקלות."
            />
            <meta property="og:image" content={LOGO_URL} />
            <meta property="og:url" content="https://www.hazmanina.co.il" />
            <link rel="canonical" href={canonicalUrl} key="canonical" />
            <link rel="icon" href="/favicon/80x80.webp" />
        </Head>
    );
};

export default SEOHead;
