import { datadogRum } from '@datadog/browser-rum';
import getConfig from 'next/config';
import { useEffect } from 'react';

export default function DatadogRumInit() {
    useEffect(() => {
        const {
            publicRuntimeConfig: { ddClientToken: clientToken, ddApplicationId: applicationId, ddEnv, ddSite }
        } = getConfig();
        if (applicationId && clientToken) {
            datadogRum.init({
                applicationId,
                clientToken,
                site: ddSite,
                service: 'hazmanina-client',
                env: ddEnv,
                version: '1.0.0',
                sessionSampleRate: 100,
                sessionReplaySampleRate: 20,
                trackUserInteractions: true,
                silentMultipleInit: true,
                defaultPrivacyLevel: 'mask-user-input'
            });

            datadogRum.startSessionReplayRecording();
        }
    }, []);

    return null;
}
